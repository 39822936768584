export const ROLES = {
    'CLIENT': 1,
    'FLEET_MANAGER': 2,
    'ADMINISTRATOR': 3,
    'SUPERADMINISTRATOR': 4,
}

export const PAGES = [
    {path: 'clients', name: 'Zakupci', permissions: [ROLES.SUPERADMINISTRATOR]},
    {path: 'users', name: 'Korisnici', permissions: [ROLES.ADMINISTRATOR, ROLES.SUPERADMINISTRATOR]},
    {path: 'collaborators', name: 'Saradnici', permissions: [ROLES.SUPERADMINISTRATOR]},
    {path: 'cars', name: 'Vozila', permissions: [ROLES.FLEET_MANAGER, ROLES.ADMINISTRATOR, ROLES.SUPERADMINISTRATOR]},
    {path: 'registrations', name: 'Registracije', permissions: [ROLES.CLIENT, ROLES.FLEET_MANAGER, ROLES.ADMINISTRATOR, ROLES.SUPERADMINISTRATOR]},
    {path: 'insurances', name: 'Osiguranja', permissions: [ROLES.CLIENT, ROLES.FLEET_MANAGER, ROLES.ADMINISTRATOR, ROLES.SUPERADMINISTRATOR]},
    {path: 'services', name: 'Servisi', permissions: [ROLES.CLIENT, ROLES.FLEET_MANAGER, ROLES.ADMINISTRATOR, ROLES.SUPERADMINISTRATOR]},
    {path: 'tires', name: 'Pneumatici', permissions: [ROLES.CLIENT, ROLES.FLEET_MANAGER, ROLES.ADMINISTRATOR, ROLES.SUPERADMINISTRATOR]},
    {path: 'damages', name: 'Oštećenja', permissions: [ROLES.CLIENT, ROLES.FLEET_MANAGER, ROLES.ADMINISTRATOR, ROLES.SUPERADMINISTRATOR]},
    {path: 'hotel', name: 'Hotel', permissions: [ROLES.FLEET_MANAGER, ROLES.ADMINISTRATOR, ROLES.SUPERADMINISTRATOR]},
]