import React from 'react';
import { Container } from "@mui/material";
  
export default function Loading() {

  return (
    <Container maxWidth="false" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
      <img src={'/images/loading.gif'} style={{width: 70, height: 70}} />
    </Container>
    ); 
  
}
