import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Typography, Divider, Button, Grid, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Paper, TextField, Link, MenuItem } from '@mui/material';
import TablePaginationActions from '../../components/TablePaginationActions';
import axios from 'axios';
  
import AppContext from '../../components/AppContext';
import ErrorHandler from '../../components/ErrorHandler';
import Loading from '../../components/Loading';

import Config from '../../utils/Config';
import {TimestampToDate, TimestampToDateTime, TimestampToMillisNumber} from '../../utils/Helpers';
import { ROLES } from '../../utils/Constants';

export default function List() {
  const navigate = useNavigate();

  const [data, setData] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter, setFilter] = useState({clientName: "", registrationPlate: "", status: "", category: "", startDate: "", endDate: ""});

  const [filterField1, setFilterField1] = useState('');
  const [filterField2, setFilterField2] = useState('');
  const [filterField3, setFilterField3] = useState('');
  const [filterField4, setFilterField4] = useState('');
  const [filterField5, setFilterField5] = useState('');
  const [filterField6, setFilterField6] = useState('');

  const { appContext, setDialogBox } = React.useContext(AppContext);

  const fetchData = async () => {
    try {
      const response = await axios.get(Config.BASE_URL + '/admin/tires/list', {
        headers: {
          Authorization: 'Bearer ' + appContext.userToken, 
          'Cache-Control': 'no-cache',
        },
      });

      setData(response.data);
    } catch (error) {
      console.error(error);
      ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSetFilterButtonClick = () => {
    setFilter({clientName: filterField1, registrationPlate: filterField2, status: filterField3, category: filterField4, startDate: filterField5, endDate: filterField6});
    setPage(0);
  };

  const handleClearFilterButtonClick = () => {
    setFilterField1('');
    setFilterField2('');
    setFilterField3('');
    setFilterField4('');
    setFilterField5('');
    setFilterField6('');

    setFilter({clientName: "", registrationPlate: "", status: "", category: "", startDate: "", endDate: ""});
  };

  console.log(data)
  
  if (data === false) {
    return (<Loading />);
  }

  function filterDateFrom(row) {
    if (filter.startDate) {
      return TimestampToMillisNumber(row.date) >= TimestampToMillisNumber(filter.startDate);
    } else {
      return true;
    }
  }

  function filterDateTo(row) {
    if (filter.endDate) {
      return TimestampToMillisNumber(row.date) <= TimestampToMillisNumber(filter.endDate);
    } else {
      return true;
    }
  }

  const deleteService = async (id) => {
    if (window.confirm('Obrisati zamenu pneumatika?')) {
      setDialogBox({show: true, type: 'loadingBox'});

      try {
        const response = await axios.get(Config.BASE_URL + '/admin/services/delete?id=' + id, {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
            'Cache-Control': 'no-cache',
          },
        });

        //console.log('Response:', response);
      } catch (error) {
        console.error('Error:', error);
        ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
      
      fetchData();

      setDialogBox({show: false});
    }
  }

  // Handle Enter key press
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent default behavior (e.g., new line in textarea)
      handleSetFilterButtonClick();
    }
  };
  
  const rows = data.filter((el) => el.clientName.toLowerCase().includes(filter.clientName.toLowerCase()))
                  .filter((el) => (el.carRegistrationPlate || '').toLowerCase().includes(filter.registrationPlate.toLowerCase()))
                  .filter((el) => (el.status || '').toLowerCase().includes(filter.status.toLowerCase()))
                  .filter((el) => (el.category || '').toLowerCase().includes(filter.category.toLowerCase()))
                  .filter(filterDateFrom)
                   .filter(filterDateTo);
  
  return (
      <Container maxWidth="false">
        <Grid container spacing={1} sx={{ my: 2 }}>
          <Grid item xs={8}>
            <Typography variant="h4">Pneumatici</Typography>
          </Grid>
          <Grid item xs={4} textAlign="right">
            <Button variant="contained" color="success" onClick={() => navigate('/tires/add')}>Dodaj zamenu pneumatika</Button>
          </Grid>
        </Grid>
        <Divider light />
        <Grid container spacing={1} sx={{ my: 2 }} onKeyDown={handleKeyDown}>
          <Grid item xs={1} textAlign="center">
            Ukupno: {rows.length}
          </Grid>
          <Grid item xs={2} textAlign="center">
            <TextField label="Zakupac" value={filterField1} onChange={(e) => setFilterField1(e.target.value)} variant="outlined" size="small" InputLabelProps={{ shrink: true }} />
          </Grid>
          <Grid item xs={2} textAlign="center">
            <TextField label="Registracija" value={filterField2} onChange={(e) => setFilterField2(e.target.value)} variant="outlined" size="small" InputLabelProps={{ shrink: true }} />
          </Grid>
          <Grid item xs={2} textAlign="center">
            <TextField select fullWidth label="Status" value={filterField3} onChange={(e) => setFilterField3(e.target.value)} variant="outlined" size="small" InputLabelProps={{ shrink: true }} >
              <MenuItem value={"Otkazan"}>Otkazan</MenuItem>
              <MenuItem value={"Rezervacija"}>Rezervacija</MenuItem>
              <MenuItem value={"Zakazan"}>Zakazan</MenuItem>
              <MenuItem value={"Završen"}>Završen</MenuItem>
          </TextField>
          </Grid>
          <Grid item xs={2} textAlign="center">
            <TextField select fullWidth label="Vrsta" value={filterField4} onChange={(e) => setFilterField4(e.target.value)} variant="outlined" size="small" InputLabelProps={{ shrink: true }} >
              <MenuItem value={"Zamena pneumatika"}>Zamena pneumatika</MenuItem>
              <MenuItem value={"Popravka pneumatika"}>Popravka pneumatika</MenuItem>
              <MenuItem value={"Ostalo"}>Ostalo</MenuItem>
          </TextField>
          </Grid>
          <Grid item xs={2} textAlign="center">
            <TextField label="OD (Datum)" value={filterField5} type="date" onChange={(e) => setFilterField5(e.target.value)} variant="outlined" size="small" InputLabelProps={{ shrink: true }} />
            <TextField label="DO (Datum)" value={filterField6} type="date" sx={{ mt: 2 }} onChange={(e) => setFilterField6(e.target.value)} variant="outlined" size="small" InputLabelProps={{ shrink: true }} />
          </Grid>
          <Grid item xs={1} textAlign="right">
            <Button variant="contained" color="primary" onClick={handleSetFilterButtonClick}>Primeni</Button>
            <Button variant="contained" color="error" sx={{ mt: 1 }} onClick={handleClearFilterButtonClick}>Reset</Button>
          </Grid>
        </Grid>
        <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelRowsPerPage="Redova po stranici"
          />
          <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead color="secondary">
              <TableRow>
                  <TableCell>Naziv</TableCell>
                  <TableCell align="left">Istorija po vozilu</TableCell>
                  <TableCell align="left">Zakupac</TableCell>
                  <TableCell align="left">Saradnik</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="left">Vrsta</TableCell>
                  <TableCell align="left">Grad</TableCell>
                  <TableCell align="left">Datum</TableCell>
                  <TableCell align="left">Upisan</TableCell>
                  <TableCell align="left"></TableCell>
              </TableRow>
              </TableHead>
              <TableBody>
              {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                  <TableRow
                  key={row.id}
                  className={"service-row status" + row.status}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={() => navigate('/tires/view?id=' + row.id)}
                  >
                  <TableCell component="th" scope="row">{row.title}</TableCell>
                  <TableCell align="left"><Link onClick={(e) => { navigate('/cars/tires?id=' + row.carId); e.stopPropagation(); }}>{row.carGarageNumber} - {row.carRegistrationPlate}</Link></TableCell>
                  <TableCell align="left">{row.clientName}</TableCell>
                  <TableCell align="left">{row.collaboratorName}</TableCell>
                  <TableCell align="left">{row.status}</TableCell>
                  <TableCell align="left">{row.category}</TableCell>
                  <TableCell align="left">{row.city}</TableCell>
                  <TableCell align="left">{TimestampToDate(row.date)}</TableCell>
                  <TableCell align="left">{TimestampToDateTime(row.created)}</TableCell>
                  <TableCell align="left">
                    <Button variant="text" color="secondary" size="small" sx={{m:0, p:0}} onClick={(e) => { navigate('/tires/edit?id=' + row.id); e.stopPropagation(); }}>Izmeni</Button>
                    {(appContext.userData.role.roleId == ROLES.SUPERADMINISTRATOR || appContext.userData.role.roleId == ROLES.ADMINISTRATOR) && (
                      <Button variant="contained" color="error" size="small" sx={{m:0, p:0}} onClick={(e) => { deleteService(row.id); e.stopPropagation(); }}>Obriši</Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
              </TableBody>
          </Table>
          </TableContainer>
          <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelRowsPerPage="Redova po stranici"
          />
      </Container>
  );
}
