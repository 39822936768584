import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Typography, Divider, Button, Grid, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Paper, TextField } from '@mui/material';
import TablePaginationActions from '../../components/TablePaginationActions';
import axios from 'axios';
  
import AppContext from '../../components/AppContext';
import ErrorHandler from '../../components/ErrorHandler';
import Loading from '../../components/Loading';

import Config from '../../utils/Config';
import {TimestampToDateTime} from '../../utils/Helpers';

export default function List() {
  const navigate = useNavigate();

  const [data, setData] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filter, setFilter] = useState({garageNumber: "", registrationPlate: "", clientName: "", model: "", hotel: ""});

  const [filterField1, setFilterField1] = useState('');
  const [filterField2, setFilterField2] = useState('');
  const [filterField3, setFilterField3] = useState('');
  const [filterField4, setFilterField4] = useState('');
  const [filterField5, setFilterField5] = useState('');

  const { appContext } = React.useContext(AppContext);

  //console.log(userToken)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(Config.BASE_URL + '/admin/cars/list', {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
            'Cache-Control': 'no-cache',
          },
        });

        setData(response.data);
      } catch (error) {
        console.error(error);
        ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    };

    fetchData();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSetFilterButtonClick = () => {
    setFilter({garageNumber: filterField1, registrationPlate: filterField2, clientName: filterField3, model: filterField4, hotel: filterField5});
  };

  const handleClearFilterButtonClick = () => {
    setFilterField1('');
    setFilterField2('');
    setFilterField3('');
    setFilterField4('');
    setFilterField5('');

    setFilter({garageNumber: "", registrationPlate: "", clientName: "", model: "", hotel: ""});
  };

  console.log(data)
  
  if (data === false) {
    return (<Loading />);
  }

  // Handle Enter key press
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent default behavior (e.g., new line in textarea)
      handleSetFilterButtonClick();
    }
  };

  const rows = data.filter((el) => el.garageNumber.toLowerCase().includes(filter.garageNumber.toLowerCase()))
                   .filter((el) => (el.registrationPlate || '').toLowerCase().includes(filter.registrationPlate.toLowerCase()))
                   .filter((el) => (el.clientName || '').toLowerCase().includes(filter.clientName.toLowerCase()))
                   .filter((el) => (el.model || '').toLowerCase().includes(filter.model.toLowerCase()))
                   .filter((el) => (el.tireHotel || '').toLowerCase().includes(filter.hotel.toLowerCase()));

  return (
      <Container maxWidth="false">
        <Grid container spacing={1} sx={{ my: 2 }}>
          <Grid item xs={12}>
            <Typography variant="h4">Hotel</Typography>
          </Grid>
        </Grid>
        <Divider light />
        <Grid container spacing={1} sx={{ my: 2 }} onKeyDown={handleKeyDown}>
          <Grid item xs={1} textAlign="center">
            Ukupno: {rows.length}
          </Grid>
          <Grid item xs={2} textAlign="center">
            <TextField label="Garažni broj" value={filterField1} onChange={(e) => setFilterField1(e.target.value)} variant="outlined" size="small" InputLabelProps={{ shrink: true }} />
          </Grid>
          <Grid item xs={2} textAlign="center">
            <TextField label="Registracija" value={filterField2} onChange={(e) => setFilterField2(e.target.value)} variant="outlined" size="small" InputLabelProps={{ shrink: true }} />
          </Grid>
          <Grid item xs={2} textAlign="center">
            <TextField label="Zakupac" value={filterField3} onChange={(e) => setFilterField3(e.target.value)} variant="outlined" size="small" InputLabelProps={{ shrink: true }} />
          </Grid>
          <Grid item xs={2} textAlign="center">
            <TextField label="Model" value={filterField4} onChange={(e) => setFilterField4(e.target.value)} variant="outlined" size="small" InputLabelProps={{ shrink: true }} />
          </Grid>
          <Grid item xs={2} textAlign="center">
            <TextField label="Hotel" value={filterField5} onChange={(e) => setFilterField5(e.target.value)} variant="outlined" size="small" InputLabelProps={{ shrink: true }} />
          </Grid>
          <Grid item xs={1} textAlign="right">
            <Button variant="contained" color="primary" onClick={handleSetFilterButtonClick}>Primeni</Button>
            <Button variant="contained" color="error" sx={{ mt: 1 }} onClick={handleClearFilterButtonClick}>Reset</Button>
          </Grid>
        </Grid>
        <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelRowsPerPage="Redova po stranici"
          />
          <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead color="secondary">
              <TableRow>
                  <TableCell>Garažni broj</TableCell>
                  <TableCell align="left">Registracija</TableCell>
                  <TableCell align="left">Zakupac</TableCell>
                  <TableCell align="left">Marka</TableCell>
                  <TableCell align="left">Model</TableCell>
                  <TableCell align="left">Hotel</TableCell>
                  <TableCell align="left"></TableCell>
              </TableRow>
              </TableHead>
              <TableBody>
              {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                  <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, 
                        '&:nth-of-type(even)': { backgroundColor: "#f6f6f6" },
                        '&:hover': { backgroundColor: "#fffbc7", cursor: 'pointer' } }}
                  onClick={() => navigate('/cars/view?id=' + row.id)}
                  >
                  <TableCell component="th" scope="row">{row.garageNumber}</TableCell>
                  <TableCell align="left">{row.registrationPlate}</TableCell>
                  <TableCell align="left">{row.clientName}</TableCell>
                  <TableCell align="left">{row.brandName}</TableCell>
                  <TableCell align="left">{row.model}</TableCell>
                  <TableCell align="left">{row.tireHotel}</TableCell>
                  <TableCell align="left"><Button variant="text" color="secondary" size="small" sx={{m:0, p:0}} onClick={(e) => { navigate('/cars/edit?id=' + row.id); e.stopPropagation(); }}>Izmeni</Button></TableCell>
                  </TableRow>
              ))}
              </TableBody>
          </Table>
          </TableContainer>
          <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelRowsPerPage="Redova po stranici"
          />
      </Container>
  );
}
