import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { Icon, TextField, Typography, Divider, Button, Grid, Container, Table, TableBody, TableCell, TableContainer, TableRow, Input, IconButton, InputAdornment } from '@mui/material';
import {FormErrorProvider, FormContainer, TextFieldElement, SelectElement, TextareaAutosizeElement, AutocompleteElement} from 'react-hook-form-mui'
import axios from 'axios';
  
import AppContext from '../../components/AppContext';
import ErrorHandler from '../../components/ErrorHandler';
import Loading from '../../components/Loading';

import Config from '../../utils/Config';

export default function Edit() {
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const [data, setData] = useState(false);
  const [cars, setCars] = useState([]);
  const [collaborators, setCollaborators] = useState([]);
  const [users, setUsers] = useState([]);

  const [uploadObjectField, setUploadObjectField] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);

  const { appContext } = React.useContext(AppContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(Config.BASE_URL + '/admin/services/get?id=' + queryParameters.get('id'), {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
            'Cache-Control': 'no-cache',
          },
        });

        // prepare for form inputs
        response.data.carId = response.data.car.carId;
        response.data.collaboratorId = response.data.collaborator?.collaboratorId;
        response.data.userId = response.data.user?.userId;
        
        // remove not needed data
        delete response.data.car;
        delete response.data.collaborator;
        delete response.data.user;

        setData(response.data);
      } catch (error) {
        console.error(error);
        ErrorHandler(error, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    };

    const fetchCars = async () => {
      try {
        const response = await axios.get(Config.BASE_URL + '/admin/cars/list', {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
            'Cache-Control': 'no-cache',
          },
        });

        for (const dataId in response.data) {
          response.data[dataId]["label"] = response.data[dataId]["garageNumber"] + " - " + response.data[dataId]["registrationPlate"];
        }

        setCars(response.data);
      } catch (error) {
        console.error(error);
        ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    };

    const fetchCollaborators = async () => {
      try {
        const response = await axios.get(Config.BASE_URL + '/admin/collaborators/list', {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
            'Cache-Control': 'no-cache',
          },
        });

        for (const dataId in response.data) {
          response.data[dataId]["label"] = response.data[dataId]["id"] + " - " + response.data[dataId]["name"].trim();
        }

        setCollaborators(response.data);
      } catch (error) {
        console.error(error);
        ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    };

    const fetchUsers = async () => {
      try {
        const response = await axios.get(Config.BASE_URL + '/admin/users/list', {
          headers: {
            Authorization: 'Bearer ' + appContext.userToken, 
            'Cache-Control': 'no-cache',
          },
        });

        for (const dataId in response.data) {
          response.data[dataId]["label"] = response.data[dataId]["phone"] + " - " + response.data[dataId]["name"];
        }

        setUsers(response.data);
      } catch (error) {
        console.error(error);
        ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
      }
    };

    fetchData();
    fetchCars();
    fetchCollaborators();
    fetchUsers();
  }, []);

  const handleFormSubmit = async (formData) => {
    formData.imageDashboard = data.imageDashboard;
    
    console.log("handleFormSubmit")
    console.log(formData)

    try {
      const response = await axios.post(Config.BASE_URL + '/admin/services/edit', formData, {
        headers: {
          Authorization: 'Bearer ' + appContext.userToken, 
        }
      });

      //console.log('Response:', response);

      navigate('/tires/list');
    } catch (error) {
      console.error('Error:', error);
      ErrorHandler(error.response, {'userToken': appContext.userToken, 'signOut': appContext.signOut});
    }
  };

  const uploadFile = (e) => {
    const reader = new FileReader()

    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')
    reader.onload = (e) => {
      setUploadedImage(reader.result)
    }
    reader.readAsDataURL(e.target.files?.item(0))
  };

  if (data === false) {
    return (<Loading />);
  }

  if (!data) {
    return (
      <Container maxWidth="false" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 300 }}>
        <Typography variant="h4">Nema podataka</Typography>
      </Container>
    )
  }

  // set uploaded image to data object
  if (uploadObjectField && uploadedImage) {
    setData({...data, [uploadObjectField]: uploadedImage});
    setUploadObjectField(null);
    setUploadedImage(null);
  }

  return (
    <>
    <Container maxWidth="false">
      <Grid container spacing={1} sx={{ my: 2 }}>
        <Typography variant="h4">Izmena servisa {data.title}</Typography>
      </Grid>
      <Divider light />
      <FormErrorProvider
        onError={(error) => {
          if (error.type === 'required') {
            return 'Popunite obavezno polje'
          } else if (error.type === 'pattern') {
            return 'Unesite ispravnu e-mail adresu'
          }
          return error?.message
        }}
      >
        <FormContainer     
          defaultValues={data}
          onSuccess={formData => handleFormSubmit(formData)}>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Naziv: <Typography variant="h6" display="inline-block" color="red">*</Typography></Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="title" size="small" fullWidth required /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Vozilo: <Typography variant="h6" display="inline-block" color="red">*</Typography></Grid>
            <Grid item xs={3} textAlign="left">
              <AutocompleteElement
                name="carId"
                matchId
                fullWidth
                required
                options={cars}
                textFieldProps={{
                  InputProps: { size: "small" },
                }}
                />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Saradnik:</Grid>
            <Grid item xs={3} textAlign="left">
              <AutocompleteElement
                name="collaboratorId"
                matchId
                fullWidth
                size="small"
                options={collaborators}
                textFieldProps={{
                  InputProps: { size: "small" },
                }}
                />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Vrsta: <Typography variant="h6" display="inline-block" color="red">*</Typography></Grid>
            <Grid item xs={3} textAlign="left">
            <SelectElement
              name="category"
              fullWidth
              required
              size="small"
              options={[
                {
                  id: 'Zamena pneumatika',
                  label: 'Zamena pneumatika'
                },
                {
                  id: 'Popravka pneumatika',
                  label: 'Popravka pneumatika'
                },
                {
                  id: 'Ostalo',
                  label: 'Ostalo'
                },
              ]}
            />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Garantni rok:</Grid>
            <Grid item xs={3} textAlign="left">
            <SelectElement
              name="warrantyPeriod"
              fullWidth
              size="small"
              options={[
                {
                  id: 'U garantnom roku',
                  label: 'U garantnom roku'
                },
                {
                  id: 'Van garantnog roka',
                  label: 'Van garantnog roka'
                },
              ]}
            />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Status: <Typography variant="h6" display="inline-block" color="red">*</Typography></Grid>
            <Grid item xs={3} textAlign="left">
            <SelectElement
              name="status"
              fullWidth
              required
              size="small"
              options={[
                {
                  id: 'Otkazan',
                  label: 'Otkazan'
                },
                {
                  id: 'Rezervacija',
                  label: 'Rezervacija'
                },
                {
                  id: 'Zakazan',
                  label: 'Zakazan'
                },
                {
                  id: 'Završen',
                  label: 'Završen'
                },
              ]}
            />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Datum:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="date" size="small" type="date" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Vremenski intervali:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="time" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left"></Grid>
            <Grid item xs={3} textAlign="left" sx={{ fontSize: 14 }}>Neophodno je da budu u sledećim formatima: 09:15 ili 12:30 - 13:45, bez slova h na kraju</Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Broj fakture:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="invoiceNumber" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Cena:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="price" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Opis:</Grid>
            <Grid item xs={3} textAlign="left"><TextareaAutosizeElement name="description" rows={5} size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Trenutna kilometraža:</Grid>
            <Grid item xs={3} textAlign="left"><TextFieldElement name="mileage" size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Fotografija kilometar sata:</Grid>
            <Grid item xs={3} textAlign="left">
              <TableContainer>
                <Table>
                  <TableBody>
                  <TableRow>
                    <TableCell style={{borderBottomWidth: 0, padding: 0}}><img src={data.imageDashboard} height={data.imageDashboard == null ? 0 : 100} /></TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell style={{borderBottomWidth: 0, padding: 0}}>
                      <TextField
                        className="uploadIcon"
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <>
                                <IconButton aria-label="upload" component="label">
                                  <Icon>publish</Icon>
                                  <input hidden type="file" onChange={(e) => {setUploadObjectField("imageDashboard"); uploadFile(e);}} />
                                </IconButton>
                            </>
                          ),
                        }}
                      />        
                      <IconButton aria-label="delete" component="label" style={{padding: 0, display: data.imageDashboard == null ? 'none' : 'inline-block' }} onClick={() => setData({...data, imageDashboard: null}) }>
                        <Icon>delete</Icon>
                      </IconButton>           
                    </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Napomena:</Grid>
            <Grid item xs={3} textAlign="left"><TextareaAutosizeElement name="note" rows={5} size="small" fullWidth /></Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={3} textAlign="left" sx={{ fontWeight: 'bold' }}>Korisnik:</Grid>
            <Grid item xs={3} textAlign="left">
              <AutocompleteElement
                name="userId"
                matchId
                fullWidth
                options={users}
                textFieldProps={{
                  InputProps: { size: "small" },
                }}
                />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={12} textAlign="center" >
            <Button type="submit" color="success" variant="contained">Izmeni</Button>
            <Button variant="contained" color="primary" sx={{mx: 1}} onClick={() => navigate('/tires/list')}>Nazad</Button>
            </Grid>
          </Grid>
      </FormContainer>
      </FormErrorProvider>
    </Container>
    </>
  );
}