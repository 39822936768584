// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiButton-active {
    background-color: white !important;
    color: #42a5f5 !important;
    font-weight: bold !important;
}

.MuiTableCell-head {
    background-color: #42a5f5;
    color: #fff !important;
}

.MuiGrid-item img {
    padding-right: 5px;
}

.FormRowOdd div {
    background-color: #eee;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

.uploadIcon input {
    width: 0 !important;
    padding: 0 !important;
}

.uploadIcon label {
    padding: 0 !important;
}

.uploadIcon fieldset {
    border: none !important;
}

.service-row:hover {
    filter: brightness(95%);
    cursor: pointer;
}
.statusOtkazan {
    background-color: #ff9999;
}
.statusRezervacija {
    background-color: #99c2ff;
}
.statusZakazan {
    background-color: #ffeb99;
}
.statusZavršen {
    background-color: #ccff99;
}

.newline-break {
    white-space: pre-wrap; /* Ensures whitespace is respected */
}

tr .MuiTableCell-root {
    padding: 4px 8px !important;
}`, "",{"version":3,"sources":["webpack://./src/main/js/main.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,yBAAyB;IACzB,4BAA4B;AAChC;;AAEA;IACI,yBAAyB;IACzB,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,sBAAsB;IACtB,0BAA0B;IAC1B,6BAA6B;AACjC;;AAEA;IACI,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;IACvB,eAAe;AACnB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;;AAEA;IACI,qBAAqB,EAAE,oCAAoC;AAC/D;;AAEA;IACI,2BAA2B;AAC/B","sourcesContent":[".MuiButton-active {\n    background-color: white !important;\n    color: #42a5f5 !important;\n    font-weight: bold !important;\n}\n\n.MuiTableCell-head {\n    background-color: #42a5f5;\n    color: #fff !important;\n}\n\n.MuiGrid-item img {\n    padding-right: 5px;\n}\n\n.FormRowOdd div {\n    background-color: #eee;\n    border-top: 1px solid #ccc;\n    border-bottom: 1px solid #ccc;\n}\n\n.uploadIcon input {\n    width: 0 !important;\n    padding: 0 !important;\n}\n\n.uploadIcon label {\n    padding: 0 !important;\n}\n\n.uploadIcon fieldset {\n    border: none !important;\n}\n\n.service-row:hover {\n    filter: brightness(95%);\n    cursor: pointer;\n}\n.statusOtkazan {\n    background-color: #ff9999;\n}\n.statusRezervacija {\n    background-color: #99c2ff;\n}\n.statusZakazan {\n    background-color: #ffeb99;\n}\n.statusZavršen {\n    background-color: #ccff99;\n}\n\n.newline-break {\n    white-space: pre-wrap; /* Ensures whitespace is respected */\n}\n\ntr .MuiTableCell-root {\n    padding: 4px 8px !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
