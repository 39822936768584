import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import App from "./App";
import ClientsList from "./routes/clients/List";
import ClientsView from "./routes/clients/View";
import ClientsEdit from "./routes/clients/Edit";
import ClientsAdd from "./routes/clients/Add";
import UsersList from "./routes/users/List";
import UsersView from "./routes/users/View";
import UsersEdit from "./routes/users/Edit";
import UsersAdd from "./routes/users/Add";
import UsersMessages from "./routes/users/Messages";
import UsersAssignedVehicles from "./routes/users/AssignedVehicles";
import CollaboratorsList from "./routes/collaborators/List";
import CollaboratorsView from "./routes/collaborators/View";
import CollaboratorsEdit from "./routes/collaborators/Edit";
import CollaboratorsAdd from "./routes/collaborators/Add";
import CarsList from "./routes/cars/List";
import CarsView from "./routes/cars/View";
import CarsEdit from "./routes/cars/Edit";
import CarsAdd from "./routes/cars/Add";
import CarsRegistrations from "./routes/cars/Registrations";
import CarsInsurances from "./routes/cars/Insurances";
import CarsServices from "./routes/cars/Services";
import CarsTires from "./routes/cars/Tires";
import CarsDamages from "./routes/cars/Damages";
import RegistrationsList from "./routes/registrations/List";
import RegistrationsView from "./routes/registrations/View";
import RegistrationsEdit from "./routes/registrations/Edit";
import RegistrationsAdd from "./routes/registrations/Add";
import InsurancesList from "./routes/insurances/List";
import InsurancesView from "./routes/insurances/View";
import InsurancesEdit from "./routes/insurances/Edit";
import InsurancesAdd from "./routes/insurances/Add";
import ServicesList from "./routes/services/List";
import ServicesView from "./routes/services/View";
import ServicesEdit from "./routes/services/Edit";
import ServicesAdd from "./routes/services/Add";
import TiresList from "./routes/tires/List";
import TiresView from "./routes/tires/View";
import TiresEdit from "./routes/tires/Edit";
import TiresAdd from "./routes/tires/Add";
import DamagesList from "./routes/damages/List";
import DamagesView from "./routes/damages/View";
import DamagesEdit from "./routes/damages/Edit";
import DamagesAdd from "./routes/damages/Add";
import HotelList from "./routes/hotel/List";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
    <BrowserRouter>
    <Routes>
        <Route path="/" element={<App />}>
            <Route path="clients/list" element={<ClientsList />} />
            <Route path="clients/view" element={<ClientsView />} />
            <Route path="clients/edit" element={<ClientsEdit />} />
            <Route path="clients/add" element={<ClientsAdd />} />
            <Route path="users/list" element={<UsersList />} />
            <Route path="users/view" element={<UsersView />} />
            <Route path="users/edit" element={<UsersEdit />} />
            <Route path="users/add" element={<UsersAdd />} />
            <Route path="users/messages" element={<UsersMessages />} />
            <Route path="users/assigned" element={<UsersAssignedVehicles />} />
            <Route path="collaborators/list" element={<CollaboratorsList />} />
            <Route path="collaborators/view" element={<CollaboratorsView />} />
            <Route path="collaborators/edit" element={<CollaboratorsEdit />} />
            <Route path="collaborators/add" element={<CollaboratorsAdd />} />
            <Route path="cars/list" element={<CarsList />} />
            <Route path="cars/view" element={<CarsView />} />
            <Route path="cars/edit" element={<CarsEdit />} />
            <Route path="cars/add" element={<CarsAdd />} />
            <Route path="cars/registrations" element={<CarsRegistrations />} />
            <Route path="cars/insurances" element={<CarsInsurances />} />
            <Route path="cars/services" element={<CarsServices />} />
            <Route path="cars/tires" element={<CarsTires />} />
            <Route path="cars/damages" element={<CarsDamages />} />
            <Route path="registrations/list" element={<RegistrationsList />} />
            <Route path="registrations/view" element={<RegistrationsView />} />
            <Route path="registrations/edit" element={<RegistrationsEdit />} />
            <Route path="registrations/add" element={<RegistrationsAdd />} />
            <Route path="insurances/list" element={<InsurancesList />} />
            <Route path="insurances/view" element={<InsurancesView />} />
            <Route path="insurances/edit" element={<InsurancesEdit />} />
            <Route path="insurances/add" element={<InsurancesAdd />} />
            <Route path="services/list" element={<ServicesList />} />
            <Route path="services/view" element={<ServicesView />} />
            <Route path="services/edit" element={<ServicesEdit />} />
            <Route path="services/add" element={<ServicesAdd />} />
            <Route path="tires/list" element={<TiresList />} />
            <Route path="tires/view" element={<TiresView />} />
            <Route path="tires/edit" element={<TiresEdit />} />
            <Route path="tires/add" element={<TiresAdd />} />
            <Route path="damages/list" element={<DamagesList />} />
            <Route path="damages/view" element={<DamagesView />} />
            <Route path="damages/edit" element={<DamagesEdit />} />
            <Route path="damages/add" element={<DamagesAdd />} />
            <Route path="hotel/list" element={<HotelList />} />
            <Route path="*" element={ <Navigate to="/clients/list" /> } />
        </Route>
    </Routes>
  </BrowserRouter>
);